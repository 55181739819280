import React from "react";
import { TableProps } from "../../types";

import TableRow from "./TableRow";
import { TableHead } from "./TableHead";

const Table: React.FC<TableProps> = ({
  titleCol1,
  titleCol2,
  tableRowData,
  currentItemId,
  onSelectItemId,
  bgColor,
}) => {
  return (
    <table
      className={`my-6 border-collapse border border-slate-200 ${
        bgColor ? bgColor : ""
      }`}
    >
      <TableHead
        titleCol1={titleCol1}
        titleCol2={titleCol2}
        countItems={tableRowData.length}
      />
      <tbody>
        {tableRowData &&
          tableRowData.map((rowData) => (
            <TableRow
              key={rowData.id}
              {...rowData}
              currentItemId={currentItemId}
              onSelectItemId={onSelectItemId}
            />
          ))}
      </tbody>
    </table>
  );
};

export default Table;
