import React, { useEffect, useState } from "react";
import sanityClient from "../../client";

import Table from "./shared/Table";
import {
  DocTypeEnum,
  GlobalComponentProps,
  ReferencedItemProps,
  TableRowProps,
} from "../types";
import { sanityDataToTableRowProps } from "./mapper";

const Artists: React.FC<GlobalComponentProps> = ({
  currentItemId,
  onSelectItemId,
}) => {
  const [tableRowData, setTableRowData] = useState<TableRowProps[] | []>([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'artist'] | order(title.de) {
              _id,
              "title": title.de, 
              "categories": artistGroups[]->{"title": title.de},
              "referencedItems": *[_type == 'programme' && references(^._id)] | order(title.de) {
                _id,
                "title": title.de
              }
            }`
      )
      .then((data: ReferencedItemProps[]) => {
        setTableRowData(
          sanityDataToTableRowProps(
            data,
            DocTypeEnum.ARTIST,
            DocTypeEnum.PROGRAMME
          )
        );
      })
      .catch(console.error);
  }, []);

  return (
    <Table
      titleCol1="Künstler*innen"
      titleCol2="in Programmen:"
      tableRowData={tableRowData}
      currentItemId={currentItemId}
      onSelectItemId={onSelectItemId}
      bgColor="bg-yellow-50"
    />
  );
};

export default Artists;
