import clsx from "clsx";
import React from "react";
import { TableRowProps } from "../../types";

const TableRow: React.FC<TableRowProps> = ({
  id,
  title,
  subtitleItems,
  col1DocType,
  col2DocType,
  col2Items,
  currentItemId,
  onSelectItemId,
}) => {
  return (
    <tr>
      <td
        className={clsx(
          "py-2 px-4 align-top border border-slate-200",
          currentItemId === id ? "bg-red-100" : ""
        )}
        onMouseEnter={onSelectItemId ? (e) => onSelectItemId(e, id) : undefined}
        onMouseLeave={onSelectItemId ? (e) => onSelectItemId(e, "") : undefined}
      >
        <h2>
          <a
            className="underline hover:text-gray-500"
            href={`https://studio.saisonbroschuere.de/desk/${col1DocType};${id}`}
          >
            {title}
          </a>
        </h2>
        {subtitleItems && (
          <p className="text-sm text-gray-500">
            {subtitleItems.map((item, idx, arr) => (
              <span key={`${item}-${idx}`}>{`${item}${
                idx < arr.length - 1 ? ", " : ""
              }`}</span>
            ))}
          </p>
        )}
      </td>
      <td className="p-4 align-top border border-slate-200">
        {col2Items && (
          <ul className="list-disc list-inside">
            {col2Items.map((item) => (
              <li
                key={`${item?._id}`}
                className={clsx(
                  currentItemId === item?._id ? "bg-red-100" : ""
                )}
                onMouseEnter={
                  onSelectItemId
                    ? (e) => onSelectItemId(e, item?._id)
                    : undefined
                }
                onMouseLeave={
                  onSelectItemId ? (e) => onSelectItemId(e, "") : undefined
                }
              >
                <a
                  className="underline hover:text-gray-500"
                  href={`https://studio.saisonbroschuere.de/desk/${col2DocType};${item?._id}`}
                >
                  {item?.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
