import React from "react";

export enum DocTypeEnum {
  ARTIST = "artist",
  PROGRAMME = "programme",
  ARTISTGROUP = "artistGroup",
  CHAPTER = "chapter",
}

export interface SingleItemProps {
  _id: string;
  title: string;
}

export interface ReferencedItemProps {
  _id: string;
  title: string;
  categories?: { title?: string }[];
  referencedItems?: SingleItemProps[];
}

export interface GlobalComponentProps {
  currentItemId?: string;
  onSelectItemId?: (
    e: React.MouseEvent<Element, MouseEvent>,
    id: string
  ) => void;
}

export interface TableRowProps extends GlobalComponentProps {
  id: string;
  col1DocType: DocTypeEnum;
  col2DocType: DocTypeEnum;
  title: string;
  subtitleItems?: string[];
  col2Items?: SingleItemProps[];
}

export interface TableProps extends GlobalComponentProps {
  titleCol1: string;
  titleCol2: string;
  tableRowData: TableRowProps[];
  bgColor: string;
}
