import { useState, useEffect } from "react";
import sanityClient from "../../client";

import Table from "./shared/Table";
import { DocTypeEnum, TableRowProps } from "../types";
import { sanityDataToTableRowProps } from "./mapper";
const Chapters = () => {
  const [tableRowData, setTableRowData] = useState<TableRowProps[]>([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'chapter'] | order(title.de) {
              _id,
              "title": title.de, 
              "referencedItems": *[_type == "programme" && references(^._id)] | order(title.de) {
                _id,
                "title": title.de
              }
            }`
      )
      .then((data) => {
        setTableRowData(
          sanityDataToTableRowProps(
            data,
            DocTypeEnum.CHAPTER,
            DocTypeEnum.PROGRAMME
          )
        );
      })
      .catch(console.error);
  }, []);

  return (
    <Table
      titleCol1="Kapitel"
      titleCol2="Programme"
      bgColor="bg-indigo-50"
      tableRowData={tableRowData}
    />
  );
};

export default Chapters;
