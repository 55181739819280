const Header = () => (
  <header className="pb-4 border-b">
    <h1 className="font-medium">
      Saisonbroschüre Referenzen Künstler*innen &lt;---&gt; Programme,
      Künstler*innegruppen und Kapitel
    </h1>
    <p className="text-sm">zeigt nur veröffentlichte (published) Beiträge</p>
  </header>
);

export default Header;
