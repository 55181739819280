import React, { useState } from "react";

import Header from "./components/main/Header";
import Artists from "./components/content/Artists";
import Programmes from "./components/content/Programmes";
import ArtistsGroups from "./components/content/ArtistGroups";
import Chapters from "./components/content/Chapters";

const App = () => {
  const [currentItemId, setCurrentItemId] = useState<string>("");
  const selectItemIdHandler = (
    e: React.MouseEvent<Element, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    setCurrentItemId(id);
  };

  return (
    <div className="App">
      <Header />
      <div className="flex space-x-12 items-start">
        <Artists
          currentItemId={currentItemId}
          onSelectItemId={selectItemIdHandler}
        />
        <Programmes
          currentItemId={currentItemId}
          onSelectItemId={selectItemIdHandler}
        />
      </div>
      <div className="flex space-x-12 items-start">
        <ArtistsGroups />
        <Chapters />
      </div>
    </div>
  );
};

export default App;
