import React from "react";

interface Props {
  titleCol1: string;
  titleCol2: string;
  countItems: number;
}

export const TableHead: React.FC<Props> = (props) => {
  return (
    <thead>
      <tr>
        <th className="p-4 border border-slate-200 pr-8 align-middle text-left">
          {props.titleCol1}{" "}
          {props.countItems && (
            <span className="font-normal">({props.countItems})</span>
          )}
        </th>
        <th className="p-4 border border-slate-200 align-middle text-left">
          {props.titleCol2}
        </th>
      </tr>
    </thead>
  );
};
