import { useState, useEffect } from "react";
import sanityClient from "../../client";

import Table from "./shared/Table";
import { DocTypeEnum, GlobalComponentProps, TableRowProps } from "../types";
import { sanityDataToTableRowProps } from "./mapper";

const Programmes: React.FC<GlobalComponentProps> = (props) => {
  const [tableRowData, setTableRowData] = useState<TableRowProps[]>([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'programme'] | order(title.de) {
              _id,
              "title": title.de, 
              "categories": chapters[]->{"title": title.de},
              "referencedItems": artists[]->{
                _id,
                "title": title.de
              }
            }`
      )
      .then((data) => {
        console.log("data", data);
        setTableRowData(
          sanityDataToTableRowProps(
            data,
            DocTypeEnum.PROGRAMME,
            DocTypeEnum.ARTIST
          )
        );
      })
      .catch(console.error);
  }, []);

  return (
    <Table
      titleCol1="Programme"
      titleCol2="Künstler*innen"
      tableRowData={tableRowData}
      currentItemId={props.currentItemId}
      onSelectItemId={props.onSelectItemId}
      bgColor="bg-cyan-50"
    />
  );
};

export default Programmes;
