import { DocTypeEnum, ReferencedItemProps } from "../types";

export const sanityDataToTableRowProps = (
  data: ReferencedItemProps[],
  col1DocType: DocTypeEnum,
  col2DocType: DocTypeEnum
) => {
  return data.map((item) => ({
    id: item._id,
    col1DocType: col1DocType,
    col2DocType: col2DocType,
    title: item.title,
    subtitleItems: item.categories?.map((cat) => cat?.title || ""),
    col2Items: item.referencedItems,
  }));
};
